import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { getCMS, getCompany, getPage, updateCMS, uploadCMSImage } from "../../../services/ApiService";
import { convertToTitle, findFileExtension, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { faChevronLeft, faChevronRight, faFileText, faTrash } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../../utils/api";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Editor from 'ckeditor5-custom-build/build/ckeditor';

const CMSInformation = (props) => {
    const lang = props.lang;
    const [companyListOptions, setCompanyListOptions] = useState([])
    const [companyPageOptions, setCompanyPageOptions] = useState([])
    const [cmsTypeListOptions, setCmsTypeListOptions] = useState([])
    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState({});
    const [data, setData] = useState({
        id: "",
        companyId: "",
        pageId: "",
        cmsData: [],
    });
    const [error, setError] = useState({
        type: "",
        section: "",
        companyId: "",
        cmsArr: []
    });

    // const cmsTypeListOptions = cmsTypeOptions(props.lang)
    // useEffect(() => {
    //     getCMSTypeOption();
    // }, [])

    // const getCMSTypeOption = async () => {
    //     const res = await getCMSType()
    //     if (res.status === 'success') {
    //         console.log(res)
    //     } else {
    //         promptMessage(lang, 'error', res.message ?? "error");
    // }
    // }
    useEffect(() => {
        getCompanyOption();
    }, [])
    // const getCategory = async () => {
    //     try {
    //         const res = await getCategoryType();
    //         if (res.status === 'success') {
    //             let options = res.data.map((item) => ({
    //                 label: item.categoryName,
    //                 value: item.categoryName,
    //                 isSelected: false,
    //                 isHidden: false,
    //             }));
    //             setCategoryListOptions(options);

    //         } else {
    //             promptMessage(lang, 'error', res.message ?? 'error');
    //         }
    //     } catch (error) {
    //         // Handle any errors that occur during the API request or data processing
    //         return [];
    //     }
    // }
    const getCompanyOption = async () => {
        try {
            const res = await getCompany();
            if (res.status === 'success') {
                let options = res.data.data.map((item) => ({
                    id: item.id,
                    label: item.companyName,
                    value: item.id,
                    isSelected: false,
                    isHidden: false,
                }));
                setCompanyListOptions(options);

            } else {
                promptMessage(lang, 'error', res.message ?? 'error');
            }
        } catch (error) {
            // Handle any errors that occur during the API request or data processing
            return [];
        }
    }

    const handleOptionSelected = async (e, name) => {
        if (name === 'company') {
            setData({
                ...data,
                companyId: e.id
            })
            const res = await getPage(e.id);
            if (res.status === 'success') {
                setCompanyPageOptions([
                    ...res.data.map(data => ({ label: `${data.name} - ${data.route}`, value: data.id }))
                ])
            }
        } else if (name === 'page') {
            const res = await getCMS(e.value, data.companyId);
            let temp = [];
            if (res.status === 'success') {
                temp = res.data.item.map(item => ({
                    ...item, isSelected: false,
                }))
            }
            setData({
                ...data,
                pageId: e.value,
                cmsData: temp,
            })
        }
    }

    const toggleAccordion = async (index) => {
        const temp = { ...data };
        temp.cmsData[index].isSelected = !temp.cmsData[index].isSelected;
        setData({ ...temp });
    }

    function inputOption(type, key, value, sIndex, cIndex, vIndex) {
        if (type === "carousel") {
            return (<div className="d-flex flex-column">
                <label className="text-capitalize mb-0">{key.replace("_", " ")}</label>
                <input className="form-control h-100" type="file" id={`${key}_${vIndex}`} onChange={(e) => updateFileInput(`${key}_${vIndex}`, e.target.files)} />
                <span className="d-flex flex-column flex-md-row gap-2 mt-2 ms-1">
                    <button type="button" className="btn btn-sm btn-primary rounded-3 p-1" onClick={() => updateFile(`${key}_${vIndex}`, sIndex, cIndex, vIndex)}>{translate(lang, 'upload')}</button>
                    <button type="button" className="btn btn-sm btn-danger rounded-3 p-1" onClick={() => clearFile(`${key}_${vIndex}`)}>{translate(lang, 'clear')}</button>
                </span>{/* <input className="form-control" type="text" value={value} onChange={(e) => updateValue(accIndex, vIndex, key, value)} /> */}
                <div className="carousel-box mt-2">
                    {
                        value.map((v, index) => {
                            let ext = findFileExtension(v.path);
                            if (["png", "jpg", "jpeg"].includes(ext.toLowerCase())) {
                                return (
                                    <div className="p-2">
                                        <img src={`${api.images}/${v.path}`} className="p-2 img-fluid" />
                                        <span className="px-2 gap-2 d-flex">
                                            {index === 0 ? <></> :
                                                <button type="button" className="btn btn-sm rounded-1 btn-primary" onClick={() => movePosition(index, 'down', 'image', sIndex, cIndex, vIndex)}>
                                                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                                                </button>
                                            }
                                            {index === value.length - 1 ? <></> :
                                                <button type="button" className="btn btn-sm rounded-1 btn-primary" onClick={() => movePosition(index, 'up', 'image', sIndex, cIndex, vIndex)}>
                                                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                                                </button>
                                            }
                                            <button type="button" className="btn btn-sm rounded-1 btn-danger ms-auto" onClick={() => removeItem(index, 'image', sIndex, cIndex, vIndex)}>
                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                            </button>
                                        </span>
                                    </div>
                                )
                            } else if (["pdf"].includes(ext.toLowerCase())) {
                                return (
                                    <div key={`carousel_images_${index}`} className="d-flex flex-row gap-2 align-item-center p-2">
                                        <span>{index + 1}.</span>
                                        <a href={`${api.images}/${v.path}`}>
                                            <FontAwesomeIcon icon={faFileText} className="me-2"></FontAwesomeIcon>
                                            <span>{v.path.split('/')[1]}</span>
                                        </a>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div >)
        }
        if (key.indexOf('image') > -1 || key.indexOf('attachment') > -1) {
            return (<div className="d-flex flex-column">
                <label className="text-capitalize mb-0">{key.replace("_", " ")}</label>
                <input className="form-control h-100" type="file" id={`${key}_${vIndex}`} onChange={(e) => updateFileInput(`${key}_${vIndex}`, e.target.files)} />
                <span className="d-flex flex-column flex-md-row gap-2 mt-2 ms-1">
                    <button type="button" className="btn btn-sm btn-primary rounded-3 p-1" onClick={() => updateFile(`${key}_${vIndex}`, sIndex, cIndex, vIndex)}>{translate(lang, 'upload')}</button>
                    <button type="button" className="btn btn-sm btn-danger rounded-3 p-1" onClick={() => clearFile(`${key}_${vIndex}`)}>{translate(lang, 'clear')}</button>
                </span>
                {/* <input className="form-control" type="text" value={value} onChange={(e) => updateValue(accIndex, vIndex, key, value)} /> */}
                <div className="picture-box mt-2" style={{ "width": "fit-content" }}>
                    {
                        value.map((v, index) => {
                            let ext = findFileExtension(v.path);
                            if (["png", "jpg", "jpeg"].includes(ext.toLowerCase())) {
                                return (
                                    <img src={`${api.images}/${v.path}`} className="p-2" />
                                )
                            } else {
                                return (
                                    <div key={`${v.path}_${index}`} className="d-flex flex-row gap-2 align-item-center p-2">
                                        <span>{index + 1}.</span>
                                        <a href={`${api.images}/${v.path}`} target="_blank">
                                            <FontAwesomeIcon icon={faFileText} className="me-2"></FontAwesomeIcon>
                                            <span>{v.path.split('/')[1]}</span>
                                        </a>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>)
        }
        // if (type === "editor") {
        //     if (key.indexOf('description') > -1) {
        //         return (
        //             <>
        //                 <div className="d-flex flex-column" ref={toolBarRef}>
        //                     {/* <CKTextEditor></CKTextEditor> */}
        //                     <div id={`text_editor_${key}_${accIndex}_${vIndex}`}></div>
        //                     <CKEditor
        //                         editor={DecoupledEditor}
        //                         data={value ?? "<p>initializing...</p>"}
        //                         // onInit={editor => {
        //                         //     // let toolbar = document.getElementById(`text_editor_${vIndex}`);
        //                         //     // if (toolbar) {
        //                         //     //     toolbar.appendChild(editor.ui.view.toolbar.element)
        //                         //     // }
        //                         //     // if (toolBarRef.current) {
        //                         //     //     toolBarRef.current.appendChild(editor.ui.view.toolbar.element);
        //                         //     // }
        //                         //     console.log(editor)
        //                         // }}
        //                         onReady={(editor) => {
        //                             let toolbar = document.getElementById(`text_editor_${key}_${accIndex}_${vIndex}`);
        //                             if (toolbar.childElementCount < 1) {
        //                                 toolbar.appendChild(editor.ui.view.toolbar.element)
        //                             }
        //                         }}
        //                         config={{
        //                             toolbar: [...ckeditorToolbar],
        //                             extraPlugins: [uploadPlugIn],
        //                         }}
        //                         onChange={(e, editor) => {
        //                             handleEditorChange(e, editor)
        //                         }}
        //                         onBlur={(event, editor) => {
        //                             // console.log('Blur.', editor);
        //                         }}
        //                         onFocus={(event, editor) => {
        //                             // console.log('Focus.', editor);
        //                         }}
        //                     />
        //                 </div>
        //             </>)
        //     } else {
        //         return (<div className="d-flex flex-column">
        //             <label className="text-capitalize mb-0">{key.replace("_", " ")}</label>
        //             <input className="form-control" type={type} value={value} onChange={(e) => updateValue(accIndex, vIndex, key, e.target.value)} />
        //         </div>)
        //     }
        // } else 
        if (type === "text" || type === 'email') {
            return (<div className="d-flex flex-column">
                <label className="text-capitalize mb-0">{key.replace("_", " ")}</label>
                <input className="form-control" type={type} value={value} onChange={(e) => updateValue(sIndex, cIndex, vIndex, key, e.target.value)} />
            </div>)
        }
    }

    const movePosition = (index, opt, key, accIndex, cIndex, vIndex) => {
        let temp = [...data.cmsData];
        let tempArr = temp[accIndex].data[cIndex].item.value[vIndex][key];
        if (tempArr.length) {
            if (opt === "up") {
                var newPos = index + 1;
                if (newPos > tempArr.length - 1) return;
            } else if (opt === "down") {
                var newPos = index - 1;
                if (newPos < 0) return;
            }
            tempArr = tempArr.map((item, iIndex) => {
                if (iIndex === index) return tempArr[newPos];
                if (iIndex === newPos) return tempArr[index];
                return item;
            });
            temp[accIndex].data[cIndex].item.value[vIndex][key] = [...tempArr];
            setData({ ...data, cmsData: [...temp] })
        }
    }

    const removeItem = (index, key, accIndex, cIndex, vIndex) => {
        let temp = [...data.cmsData];
        let tempArr = temp[accIndex].data[cIndex].item.value[vIndex][key];
        if (tempArr.length) {
            tempArr.splice(index, 1);
            temp[accIndex].data[cIndex].item.value[vIndex][key] = [...tempArr];
            setData({ ...data, cmsData: [...temp] })
        }
    }

    const updateFileInput = async (key, value) => {
        let temp = { ...uploadedFile };
        temp[key] = value;
        setUploadedFile({ ...temp });
    }

    const updateFile = async (key, accIndex, cIndex, vIndex) => {
        let temp = { ...uploadedFile };
        let files = temp[key];
        if (files.length < 1) {
            return;
        }
        const formData = new FormData();
        Swal.showLoading();
        for (let x = 0; x < files.length; x++) {
            formData.append(
                "image",
                files[x],
                files[x].name
            );
        }
        let res = await uploadCMSImage(formData);
        if (res.status === 'success') {
            Swal.hideLoading();
            Swal.fire({
                title: translate(lang, 'success'),
                html: `<p class="text-uppercase">${res.message}</p>`,
                icon: 'success',
                timer: 1000,
            });
            clearFile(key)
            // update data
            let result = res.data.images;
            let temp = [...data.cmsData];
            let target = key.split("_");
            temp[accIndex].data[cIndex].item.value[vIndex][target[0]] = [...temp[accIndex].data[cIndex].item.value[vIndex][target[0]], ...result];
            // console.log(temp[accIndex].item.value[vIndex], key, result);
            setData({ ...data, cmsData: [...temp] });
        } else {
            Swal.hideLoading();
            Swal.fire({
                title: translate(lang, 'error'),
                html: `<p class="text-uppercase">${res.message}</p>`,
                icon: 'error',
                timer: 1000,
            })
        }
    }

    const clearFile = async (key) => {
        const temp = { ...uploadedFile };
        delete temp[key];
        document.getElementById(`${key}`).value = "";
        console.log(temp);
        setUploadedFile({ ...temp })
    }

    const updateValue = async (sIndex, cIndex, vIndex, key, value) => {
        let temp = data.cmsData;
        if (sIndex > -1 && cIndex > -1 && vIndex > -1) {
            temp[sIndex].data[cIndex].item.value[vIndex][key] = value;
        }
        setData({ ...data, cmsData: [...temp] });
    }

    const submitForm = async () => {
        let check = true;
        // const formData = new FormData();
        // if (data.type === "editor") {
        //     cms.forEach((item, index) => {
        //         if (item.title) {
        //             formData.append(`title_${index}`, item.title)
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].title = translate(lang, "errorRequired");
        //         }
        //         if (item.description) {
        //             formData.append(`description_${index}`, item.description)
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].description = translate(lang, "errorRequired");
        //         }

        //         if (item.image.length > 0) {
        //             if (item.image[0] instanceof File) {
        //                 // `item.image[0]` is a file, no need to JSON.stringify
        //                 formData.append(`images_${index}`, item.image[0]);
        //             }
        //             else {
        //                 // `item.image[0]` is not a file, so JSON.stringify it
        //                 formData.append(`images_${index}`, JSON.stringify(item.image));
        //             }
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].image = translate(lang, "errorRequired");
        //         }
        //         item.html_content.title.forEach((item, index) => {
        //             if (item) {
        //                 formData.append(`html_content_title_${index}`, item)
        //             }
        //             else {
        //                 check = false;
        //                 NewError.cmsArr[0].html_content.title[index] = translate(lang, "errorRequired");
        //             }
        //             if (cms[0].html_content.description[index]) {
        //                 formData.append(`html_content_description_${index}`, cms[0].html_content.description[index])
        //             }
        //             else {
        //                 check = false;
        //                 NewError.cmsArr[0].html_content.description[index] = translate(lang, "errorRequired");
        //             }
        //             if (cms[0].html_content.link[index]) {
        //                 formData.append(`html_content_link_${index}`, cms[0].html_content.link[index])
        //             }
        //             // else{
        //             //     check = false;
        //             //     NewError.cmsArr[0].html_content.link[index] = translate(lang, "errorRequired");
        //             // }

        //         })
        //         if (item.html_content.image) {
        //             item.html_content.image.forEach((item, index) => {
        //                 // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //                 if (item instanceof File) {
        //                     formData.append(`html_content_image_${index}`, item);
        //                 }
        //                 else {
        //                     formData.append(`html_content_image_${index}`, JSON.stringify(item));
        //                 }

        //             });

        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[0].html_content.images = translate(lang, "errorRequired");
        //         }


        //     });

        // }
        // else if (data.type === "news") {
        //     cms.forEach((item, index) => {
        //         if (item.title) {
        //             formData.append(`title_${index}`, item.title)
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].title = translate(lang, "errorRequired");
        //         }
        //         if (cms[0].description) {
        //             formData.append(`description_${index}`, item.description)
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].description = translate(lang, "errorRequired");
        //         }

        //         if (item.image.length > 0) {
        //             if (item.image[0] instanceof File) {
        //                 // `item.image[0]` is a file, no need to JSON.stringify
        //                 formData.append(`images_${index}`, item.image[0]);
        //             }
        //             else {
        //                 // `item.image[0]` is not a file, so JSON.stringify it
        //                 formData.append(`images_${index}`, JSON.stringify(item.image));
        //             }
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].image = translate(lang, "errorRequired");
        //         }

        //         if (item.category) {
        //             // let category = []
        //             // item.category.forEach((e) => {
        //             //     category.push(e.value)
        //             // });
        //             formData.append(`categoryName_${index}`, item.category);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].category = translate(lang, "errorRequired");
        //         }
        //         item.html_content.title.forEach((item, index) => {
        //             if (item) {
        //                 formData.append(`html_content_title_${index}`, item)
        //             }
        //             else {
        //                 check = false;
        //                 NewError.cmsArr[0].html_content.title[index] = translate(lang, "errorRequired");
        //             }
        //             if (cms[0].html_content.description[index]) {
        //                 formData.append(`html_content_description_${index}`, cms[0].html_content.description[index])
        //             }
        //             else {
        //                 check = false;
        //                 NewError.cmsArr[0].html_content.description[index] = translate(lang, "errorRequired");
        //             }
        //             if (cms[0].html_content.link[index]) {
        //                 formData.append(`html_content_link_${index}`, cms[0].html_content.link[index])
        //             }
        //             // else{
        //             //     check = false;
        //             //     NewError.cmsArr[0].html_content.link[index] = translate(lang, "errorRequired");
        //             // }

        //         })
        //         if (item.html_content.image) {
        //             item.html_content.image.forEach((item, index) => {
        //                 // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //                 if (item instanceof File) {
        //                     formData.append(`html_content_image_${index}`, item);
        //                 }
        //                 else {
        //                     formData.append(`html_content_image_${index}`, JSON.stringify(item));
        //                 }

        //             });

        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[0].html_content.images = translate(lang, "errorRequired");
        //         }
        //     });

        // }
        // else if (data.type === "carousel") {
        //     if (cms[0].image.length > 0) {
        //         cms[0].image.forEach((item, index) => {
        //             // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //             if (item instanceof File) {
        //                 formData.append(`images_${index}`, item);
        //             }
        //             else {
        //                 formData.append(`images_${index}`, JSON.stringify(item));

        //             }

        //         });

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }

        //     if (cms[0].title) {
        //         formData.append(`title_0`, cms[0].title);

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].title = translate(lang, "errorRequired");
        //     }

        // }
        // else if (data.type === "description") {
        //     cms.forEach((item, index) => {
        //         // Append each item with a unique key (e.g., question_0, question_1, etc.)
        //         if (item.title) {
        //             formData.append(`title_${index}`, item.title);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].title = translate(lang, "errorRequired");
        //         }
        //         if (cms[0].description.length > 0) {
        //             cms[0].description.forEach((item, index) => {
        //                 if (item !== "") {
        //                     formData.append(`description_${index}`, item);
        //                 }
        //                 else {
        //                     check = false;
        //                     NewError.cmsArr[0].description[index] = translate(lang, "errorRequired");
        //                 }
        //             })
        //         }

        //     });

        // }
        // else if (data.type === "entries") {
        //     cms.forEach((item, index) => {
        //         // Append each item with a unique key (e.g., question_0, question_1, etc.)
        //         if (item.title) {
        //             formData.append(`title_${index}`, item.title);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].title = translate(lang, "errorRequired");
        //         }
        //         if (item.description) {
        //             formData.append(`description_${index}`, item.description);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].description = translate(lang, "errorRequired");
        //         }


        //     });

        // }
        // else if (data.type === "information") {
        //     if (cms[0].description) {
        //         formData.append(`description_${0}`, cms[0].description);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].description = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].image) {
        //         if (cms[0].image[0] instanceof File) {
        //             // `item.image[0]` is a file, no need to JSON.stringify
        //             formData.append(`images_${0}`, cms[0].image[0]);
        //         }
        //         else {
        //             // `item.image[0]` is not a file, so JSON.stringify it
        //             formData.append(`images_${0}`, JSON.stringify(cms[0].image));
        //         }
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }

        // }
        // else if (data.type === "details") {
        //     if (cms[0].description) {
        //         formData.append(`description_0`, cms[0].description);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].description = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].image.length > 0) {
        //         cms[0].image.forEach((item, index) => {
        //             // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //             if (item instanceof File) {
        //                 formData.append(`images_${index}`, item);
        //             }
        //             else {
        //                 formData.append(`images_${index}`, JSON.stringify(item));

        //             }
        //         });

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }

        // }
        // else if (data.type === "caption") {
        //     if (cms[0].smallTitle) {
        //         formData.append(`smallTitle_0`, cms[0].smallTitle);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].smallTitle = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].title) {
        //         formData.append(`title_0`, cms[0].title);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].title = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].description) {
        //         formData.append(`description_0`, cms[0].description);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].description = translate(lang, "errorRequired");
        //     }

        // }
        // else if (data.type === "company") {
        //     if (cms[0].title) {
        //         formData.append(`title_0`, cms[0].title);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].title = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].description) {
        //         formData.append(`description_0`, cms[0].description);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].description = translate(lang, "errorRequired");
        //     }

        //     if (cms[0].image.length > 0) {
        //         cms[0].image.forEach((item, index) => {
        //             // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //             if (item instanceof File) {
        //                 formData.append(`images_0`, item);
        //             }


        //         });

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].category) {
        //         cms[0].category.forEach((item, index) => {
        //             formData.append(`categoryName_${index}`, item.value);
        //         })
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].category = translate(lang, "errorRequired");
        //     }


        // }
        // else if (data.type === "descriptionWithCategory") {
        //     if (cms[0].title) {
        //         formData.append(`title_0`, cms[0].title);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].title = translate(lang, "errorRequired");
        //     }


        //     if (cms[0].category) {
        //         cms[0].category.forEach((item, index) => {
        //             formData.append(`categoryName_${index}`, item.value);
        //         })
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].category = translate(lang, "errorRequired");
        //     }


        // }
        // else if (data.type === "history") {
        //     cms.forEach((item, index) => {
        //         // Append each item with a unique key (e.g., question_0, question_1, etc.)
        //         if (item.smallTitle) {
        //             formData.append(`smallTitle_${index}`, item.smallTitle);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].smallTitle = translate(lang, "errorRequired");
        //         }
        //         if (item.title) {
        //             formData.append(`title_${index}`, item.title);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].title = translate(lang, "errorRequired");
        //         }
        //         if (item.description) {
        //             formData.append(`description_${index}`, item.description);
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].description = translate(lang, "errorRequired");
        //         }
        //         if (item.image) {
        //             if (item.image[0] instanceof File) {
        //                 // `item.image[0]` is a file, no need to JSON.stringify
        //                 formData.append(`images_${index}`, item.image[0]);
        //             }
        //             else {
        //                 // `item.image[0]` is not a file, so JSON.stringify it
        //                 formData.append(`images_${index}`, JSON.stringify(item.image));
        //             }
        //         }
        //         else {
        //             check = false;
        //             NewError.cmsArr[index].image = translate(lang, "errorRequired");
        //         }

        //     });
        // }
        // else if (data.type === "posting") {
        //     if (cms[0].title) {
        //         formData.append(`title_${0}`, cms[0].title);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].title = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].description) {
        //         formData.append(`description_${0}`, cms[0].description);
        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].description = translate(lang, "errorRequired");
        //     }
        //     if (cms[0].image.length > 0) {
        //         cms[0].image.forEach((item, index) => {
        //             // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //             if (item instanceof File) {
        //                 formData.append(`images_${index}`, item);
        //             }
        //             else {
        //                 formData.append(`images_${index}`, JSON.stringify(item));

        //             }

        //         });

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }
        // }
        // else if (data.type === "images") {
        //     if (cms[0].image.length > 0) {
        //         cms[0].image.forEach((item, index) => {
        //             // Append each item with a unique key (e.g., question_0, question_1, etc.)  
        //             if (item instanceof File) {
        //                 formData.append(`images_${index}`, item);
        //             }
        //             else {
        //                 formData.append(`images_${index}`, JSON.stringify(item));

        //             }

        //         });

        //     }
        //     else {
        //         check = false;
        //         NewError.cmsArr[0].image = translate(lang, "errorRequired");
        //     }


        //     // formData.append(`title`, item.title);
        //     // formData.append(`images_${index}`, item.image[0]);
        //     // formData.append(`text`, item.description);
        // }
        // else {
        //     check = false;
        //     NewError.type = translate(lang, "errorRequired");
        // }
        // setError(NewError);

        let payload = [...data.cmsData];
        delete payload[0].isSelected;
        console.log(payload);
        // setLoading(true);
        if (check) {
            let res;
            res = await updateCMS(data.pageId, payload);
            if (res.status === 'success') {
                setLoading(false);
                Swal.fire({
                    title: translate(lang, 'success'),
                    html: `<p class="text-uppercase">${res.message}</p>`,
                    icon: 'success',
                })
            } else {
                setLoading(false);
                Swal.fire({
                    title: translate(lang, 'error'),
                    html: `<p class="text-uppercase">${res.message}</p>`,

                    icon: 'error',
                })
            }
        } else {
            setLoading(false);
            promptMessage(lang, 'error', translate(lang, "formErrorMessage"));
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12 f-2">
                    <div className="card col-12 shadow">
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group py-2 col-12 col-md-8 col-lg-4 px-2">
                                    <label className="form-label">{translate(lang, 'companyName')}</label>
                                    <Select
                                        className="form-control"
                                        value={companyListOptions.filter(
                                            (option) => option.value === data.companyId
                                        )}
                                        onChange={(e) => handleOptionSelected(e, "company")}
                                        options={companyListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    {error.companyId === "" ? <></> : <p className="text-danger">{error.companyId}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-8 col-lg-4 px-2">
                                    <label className="form-label">{translate(lang, 'page')} - {translate(lang, 'route')}</label>
                                    <Select
                                        className="form-control"
                                        value={companyPageOptions.filter((option) =>
                                            option.value === data.pageId
                                        )}
                                        // isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "page")}
                                        options={companyPageOptions}
                                        // options={cmsTypeListOptions && cmsTypeListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })

                                        }}
                                    />
                                    {error.id === "" ? <></> : <p className="text-danger">{error.id}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="px-3 cms-content">
                            {data && data.cmsData.map((section, sIndex) => {
                                return (
                                    <Accordion expanded={section.isSelected} className="section-layer" key={`${section.section}_${sIndex}`}>
                                        <AccordionSummary onClick={() => toggleAccordion(sIndex)} className="" style={{ backgroundColor: `var(--primary)` }}>
                                            <div className="text-capitalize text-white">{translate(lang, 'section')} - {section.section}</div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div>
                                                {section.data && section.data.map((cms, cIndex) => {
                                                    return (
                                                        <Accordion className="data-layer" key={`cms_${cIndex}`}>
                                                            <AccordionSummary style={{ backgroundColor: `var(--secondary)` }}>
                                                                <div className="text-capitalize text-white">{convertToTitle(cms.name)}</div>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ padding: 0 }}>
                                                                {cms.item.value.map((v, vIndex) => {
                                                                    let keys = Object.keys(v);
                                                                    return (
                                                                        <div key={`${v}_${vIndex}`}>
                                                                            <div className="p-4">
                                                                                {
                                                                                    keys.map((k, kIndex) => {
                                                                                        if (k !== 'type') {
                                                                                            if (['title', 'description'].includes(k) && typeof v[k] === 'object') {
                                                                                                return (v[k].map((inner, inIndex) => {
                                                                                                    if (typeof inner === 'string') {
                                                                                                        return (<div key={`${k}_inner_${inIndex}`} className="d-flex flex-column gap-1">
                                                                                                            {inputOption('text', k, inner, sIndex, cIndex, vIndex)}
                                                                                                        </div>)
                                                                                                    } else if (typeof inner === 'object') {
                                                                                                        let descriptionKeys = Object.keys(inner);
                                                                                                        return (<div className="card shadow p-3 mt-3">
                                                                                                            {
                                                                                                                descriptionKeys.map((kd, kdIndex) => {
                                                                                                                    if (kd !== 'type') {
                                                                                                                        return (<div key={`description_inner_${kd}_${kdIndex}`} className="d-flex flex-column gap-1">
                                                                                                                            {inputOption(inner.type, kd, inner[kd], sIndex, cIndex, vIndex)}
                                                                                                                        </div>)
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>)
                                                                                                    }
                                                                                                }))
                                                                                                // let descriptionKeys = Object.keys(v[k]);
                                                                                                // descriptionKeys.map((kd, kdIndex) => {
                                                                                                //     if (kd !== 'type') {
                                                                                                //         if (['title', 'description'].includes(kd)) {
                                                                                                //             return [...Array(v[k].length)].map((item) => {
                                                                                                //                 if (typeof item === 'string') {
                                                                                                //                     (
                                                                                                //                         <div key={`${k}_${kd}_${kdIndex}`} className="d-flex flex-column gap-1">
                                                                                                //                             {inputOption(v.type, k, item, sIndex, cIndex, vIndex)}
                                                                                                //                         </div>
                                                                                                //                     )
                                                                                                //                 } else {
                                                                                                //                     (
                                                                                                //                         <div key={`${k}_${kd}_${kdIndex}`} className="d-flex flex-column gap-1">
                                                                                                //                             {inputOption(v.type, k, item, sIndex, cIndex, vIndex)}
                                                                                                //                         </div>
                                                                                                //                     )
                                                                                                //                 }
                                                                                                //             })
                                                                                                //         } else {
                                                                                                //             return (
                                                                                                //                 <div key={`${k}_${kIndex}`} className="d-flex flex-column gap-1">
                                                                                                //                     {inputOption(v.type, k, v[k], sIndex, cIndex, vIndex)}
                                                                                                //                 </div>
                                                                                                //             )
                                                                                                //         }
                                                                                                //     }
                                                                                                // })
                                                                                                return [...Array(v[k].length)].map((item) => (
                                                                                                    <div key={`${k}_${kIndex}`} className="d-flex flex-column gap-1">
                                                                                                        {inputOption(v.type, k, v[k][item], sIndex, cIndex, vIndex)}
                                                                                                    </div>
                                                                                                ))
                                                                                            } else {
                                                                                                return (
                                                                                                    <div key={`${k}_${kIndex}`} className="d-flex flex-column gap-1">
                                                                                                        {inputOption(v.type, k, v[k], sIndex, cIndex, vIndex)}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            {vIndex < cms.item.value.length - 1 ? <hr /> : <></>}
                                                                        </div>
                                                                    )
                                                                    // inputOption(v.type, )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                                // <Accordion>
                                //     <AccordionSummary
                                //         aria-controls="panel1-content"
                                //         id="panel1-header"
                                //     >
                                //         <Typography>Accordion 1</Typography>
                                //     </AccordionSummary>
                                //     <AccordionDetails>
                                //         <Typography>
                                //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                //             malesuada lacus ex, sit amet blandit leo lobortis eget.
                                //         </Typography>
                                //     </AccordionDetails>
                                // </Accordion>
                            })}
                        </div>
                        {data ? (
                            <div className="card-footer">
                                <div className="d-flex flex-row">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => submitForm()} disabled={loading ? true : false}>{translate(lang, `save`)}</button>
                                    {/* <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/settings/QnA')}>{translate(lang, "back")}</button> */}
                                </div>
                            </div>
                        ) : null}

                    </div>
                </div >
            </div >
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CMSInformation);